import {EnvironmentInterface} from "./EnvironmentInterface";

export const environment: EnvironmentInterface = {
  url: 'https://dashboard.rvc.digital',
  production: true,
  cookiesValidityHours: 10,
  profile: 'PROD',
  serviceUrl: 'https://zuul.rvc.digital',
  wsUrl: 'https://websocket.rvc.digital/rvc-websocket',
  jcdAddonUrl: 'https://zuul.rvc.digital/jcd-addon',
  rawDataUrl: 'https://zuul.rvc.digital',
  keycloak: {
    issuer: 'https://authentication.rvc.digital/auth/',
    realm: 'Rvc',
    clientId: 'frontend',
  },
  providableEnvironment: {
    locale: {
      lang: 'en',
      currency: 'huf',
    },
    notifications: {
      type: "toastr",
      alertBox: {
        openTime: 5000,
        fadeTime: 200,
      },
    },
    grid: {
      breakpointMobile: 320,
      breakpointTablet: 780,
      breakpointDesktop: 1024,
      breakpointXlarge: 1280,
      breakpoint2xlarge: 1920,
    },
    table: {
      paginationType: "get",
    },
    api: {
      protocol: "https",
      host: null,
      port: null,
      path: null,
      auth: {
        loginMediaType: 'json',
        initLogin: false,
        sessionLifetime: 200,
      },
      redirectOn401: false
    },
    date: {
      dateTimeFormatJSON: "string",
      dateFormatJSON: "string",
      dateTimeFormat: "string",
      dateFormat: "string",
    },
    login: {
      allRightsReserved: true,
    },
    popup: {
      buttonType: 'medium'
    }
  },
};
